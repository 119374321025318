/* eslint-disable */
import { createContext, FC, useState, useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { useQuery, useQueryClient } from 'react-query';
import {
  AboApi,
  AdminBillingApi,
  FormApi,
  ManageApi,
  TodoApi,
  UserApi,
} from '../api';
import { TokenData, CategoryElement, Client } from '../types';
import { getCookie, i18n, setCookie, deleteCookie, theme } from '../utils';
import { authCookieName, appHistory } from '../utils/constants';
import snackbarUtils from '../utils/snackbar/snackbar-utils';
import { LocalizedStrings } from 'react-localization';
import { useMediaQuery } from '@mui/material';
import { isMainThread } from 'worker_threads';
import { BreadCrumbSegment } from '../types/breadcrumb-segment';

export interface IAppContext {
  isAuthenticated: boolean;
  authToken: string | undefined;
  i18n: LocalizedStrings<any>;
  authenticate: (authToken: string) => void;
  changedCategoryData: CategoryElement[];
  updateChangedCategoryData: (categoryData: CategoryElement) => void;
  removeChangedCategoryData: (id: string) => void;
  getAndRemoveGroupData: (groupId: string) => CategoryElement[];
  removeGroupData: (groupdId: string) => void;
  getGroupData: (groupdId: string) => CategoryElement[];
  getGroupDataError: (groupdId: string | string[]) => CategoryElement[];
  getAndRemoveGroupDataByIdAndGroupId: (
    groupId: string,
    id: string,
  ) => CategoryElement[];
  unsavedChanges: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  saveAll?: boolean;
  saveChanges: (forceDelete?: boolean) => void;
  discardChanges: () => void;
  clientId?: string;
  setClientId?: (categoryId: string | undefined) => void;
  clientList?: Client[];
  setClientList: (list: [] | undefined) => void;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  userId: string;
  user: TokenData | undefined;
  logout: () => void;
  recoveryCode: string;
  setRecoveryCode: (rc: string) => void;
  recoveryCodeDialogOpen: boolean;
  setRecoveryCodeDialogOpen: (open: boolean) => void;
  familyActive: boolean;
  riskActive: boolean;
  basicActive: boolean;
  avatarActive: boolean;
  genoGrammActive: boolean;
  billingActive: boolean;
  readOnly?: boolean;
  setReadOnly: (readOnly: boolean) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  isMobile: boolean;
  isControlling: boolean;
  sevDeskActive: boolean;
  setSevDeskActive: (active: boolean) => void;
  isTeamLead: boolean;
  newTodos: number;
  newProtocols: number;
  newOrgMessages: {
    id: string;
    subject: string;
    message: string;
    creationDate: string;
  }[];
  breadCrumbSegments: BreadCrumbSegment[];
  setBreadCrumbSegments: (breadCrumbSegments: BreadCrumbSegment[]) => void;
  backendVersion: string;
  features: string[];
}

const authToken = getCookie(authCookieName);
const isAuthenticated = authToken !== undefined;
let isAdmin = false;
let isSuperAdmin = false;
let userId = '';
let user: TokenData | undefined = undefined;
let isControlling = false;
const decAuthToken: TokenData | null = decodeToken(authToken || '');
if (decAuthToken) {
  if (decAuthToken.role === 'ADMIN') {
    isAdmin = true;
  } else if (decAuthToken.role === 'SUPERADMIN') {
    isAdmin = true;
    isSuperAdmin = true;
  } else if (decAuthToken.role === 'CONTROLLING') {
    isControlling = true;
  }
  userId = decAuthToken.userId;
  user = decAuthToken;
}

const initialContext: IAppContext = {
  isAuthenticated,
  authToken,
  i18n,
  authenticate: () => {},
  updateChangedCategoryData: () => {},
  removeChangedCategoryData: () => {},
  getAndRemoveGroupData: () => [],
  removeGroupData: () => {},
  getGroupData: () => [],
  getGroupDataError: () => [],
  getAndRemoveGroupDataByIdAndGroupId: () => [],
  changedCategoryData: [],
  unsavedChanges: false,
  setUnsavedChanges: () => {},
  discardChanges: () => {},
  saveChanges: () => {},
  setClientList: () => {},
  isAdmin,
  isSuperAdmin,
  userId,
  user,
  logout: () => {},
  recoveryCode: '',
  setRecoveryCode: () => {},
  recoveryCodeDialogOpen: false,
  setRecoveryCodeDialogOpen: () => {},
  basicActive: false,
  familyActive: false,
  riskActive: false,
  genoGrammActive: false,
  avatarActive: false,
  billingActive: false,
  readOnly: false,
  setReadOnly: () => {},
  drawerOpen: false,
  setDrawerOpen: () => {},
  isMobile: false,
  isControlling,
  sevDeskActive: false,
  setSevDeskActive: () => {},
  isTeamLead: false,
  newTodos: 0,
  newProtocols: 0,
  newOrgMessages: [],
  breadCrumbSegments: [],
  setBreadCrumbSegments: () => {},
  backendVersion: '...',
  features: [],
};

export const AppContext = createContext<IAppContext>(initialContext);

interface AppProviderProps {
  test?: boolean;
}

const AppProvider: FC<AppProviderProps> = (props) => {
  const [authToken, setAuthToken] = useState(getCookie(authCookieName));
  const [clientId, setClientId] = useState<string>();
  const [clientList, setClientList] = useState<[] | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialContext.isAuthenticated,
  );
  const [unsavedChanges, setUnsavedChanges] = useState(
    initialContext.unsavedChanges,
  );
  const [changedCategoryData, setChangedCategoryData] = useState<
    CategoryElement[]
  >([]);
  const [isTeamLead, setIsTeamLead] = useState(initialContext.isTeamLead);
  const [isAdmin, setIsAdmin] = useState(initialContext.isAdmin);
  const [isSuperAdmin, setIsSuperAdmin] = useState(initialContext.isAdmin);
  const [userId, setUserId] = useState(initialContext.userId);
  const [user, setUser] = useState(initialContext.user);
  const [saveAll, setSaveAll] = useState<boolean>();
  const queryClient = useQueryClient();
  const [recoveryCode, setRecoveryCode] = useState('');
  const [recoveryCodeDialogOpen, setRecoveryCodeDialogOpen] = useState(false);
  const [basicActive, setBasicActive] = useState(false);
  const [familyActive, setFamilyActive] = useState(false);
  const [riskActive, setRiskActive] = useState(false);
  const [genoGrammActive, setGenoGrammActive] = useState(false);
  const [avatarActive, setAvatarActive] = useState(false);
  const [billingActive, setBillingActive] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [isControlling, setIsControlling] = useState(false);
  const [sevDeskActive, setSevDeskActive] = useState(
    initialContext.sevDeskActive,
  );
  const [newTodos, setNewTodos] = useState(initialContext.newTodos);
  const [newProtocols, setNewProtocols] = useState(initialContext.newProtocols);
  const [newOrgMessages, setNewOrgMessages] = useState(
    initialContext.newOrgMessages,
  );
  const [features, setFeatures] = useState(initialContext.features);
  const [backendVersion, setBackendVersion] = useState(
    initialContext.backendVersion,
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [drawerOpen, setDrawerOpen] = useState(!isSmallScreen);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [breadCrumbSegments, setBreadCrumbSegments] = useState<
    BreadCrumbSegment[]
  >([]);

  useQuery(['getBackendVersion'], () => ManageApi.getVersion(), {
    onSuccess: (version) => setBackendVersion(version),
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Attach the event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useQuery(['modules', authToken], () => AboApi.listAbos(authToken), {
    onSuccess: (res) => {
      setBasicActive(res.data.basicActive);
      setFamilyActive(res.data.familyActive);
      setRiskActive(res.data.riskActive);
      setBillingActive(res.data.billingActive);
      setAvatarActive(res.data.avatarActive);
      setGenoGrammActive(res.data.genoGrammActive);
    },
    enabled: authToken !== undefined,
  });

  useQuery(['entries', authToken], () => UserApi.getUserInfo(authToken), {
    onSuccess: (res) => {
      setNewTodos(res.data.newTodos);
      setNewProtocols(res.data.newProtocols);
      setNewOrgMessages(res.data.newOrgMessages);
      setFeatures(res.data.features);
    },
    enabled: authToken !== undefined,
  });

  useQuery(
    ['sevDeskActive', authToken],
    () => AdminBillingApi.getSevDeskStatus(authToken),
    {
      onSuccess: (res) => {
        setSevDeskActive(res.data);
      },
      enabled:
        authToken !== undefined &&
        user?.role !== undefined &&
        (user?.role === 'ADMIN' || user?.role === 'SUPERADMIN'),
    },
  );

  useEffect(() => {
    const decodedAuthToken: TokenData | null = decodeToken(authToken || '');
    if (decodedAuthToken) {
      if (decodedAuthToken.role === 'ADMIN') {
        setIsAdmin(true);
      } else if (decodedAuthToken.role === 'SUPERADMIN') {
        setIsAdmin(true);
        setIsSuperAdmin(true);
      } else if (decodedAuthToken.role === 'CONTROLLING') {
        setIsControlling(true);
      } else {
        setIsAdmin(false);
        setIsSuperAdmin(false);
      }
      if (decAuthToken?.teamLeader) {
        setIsTeamLead(decAuthToken?.teamLeader);
      } else {
        setIsTeamLead(false);
      }

      setUserId(decodedAuthToken.userId);
      setUser(decodedAuthToken);
      queryClient.invalidateQueries(['modules']);
    }
  }, [authToken]);

  useEffect(() => {
    setDrawerOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const authenticate = (authToken: string) => {
    setCookie(authCookieName, authToken);
    setIsAuthenticated(true);
    setAuthToken(authToken);
  };

  const logout = () => {
    setClientList([]);
    setUserId('');
    setIsAdmin(false);
    setIsSuperAdmin(false);
    setAuthToken(undefined);
    deleteCookie(authCookieName);
    snackbarUtils.success(i18n.logoutSuccess);
    setIsAuthenticated(false);
    appHistory.push('/login');
  };

  const updateChangedCategoryData = (updateCategory: CategoryElement) => {
    let updated = false;

    changedCategoryData?.forEach((category, index) => {
      if (category?.groupId === updateCategory.groupId) {
        if (category?.id === updateCategory.id) {
          setChangedCategoryData((changes) => {
            changes[index] = updateCategory;
            return changes;
          });
          updated = true;
          return;
        }
      }
    });
    if (!updated) {
      changedCategoryData.push(updateCategory);
      setChangedCategoryData([...changedCategoryData]);
    }

    checkIfCategoryChange();
  };

  const removeChangedCategoryData = (id: string) => {
    setChangedCategoryData(
      changedCategoryData.filter((data) => data?.id === id),
    );
    checkIfCategoryChange();
  };

  const getAndRemoveGroupDataByIdAndGroupId = (groupId: string, id: string) => {
    const groupData = changedCategoryData.filter(
      (data) => data?.groupId === groupId && data?.id === id,
    );
    const newCD = changedCategoryData.filter(
      (data) => data?.groupId !== groupId && data?.id === id,
    );
    setChangedCategoryData(newCD);
    checkIfCategoryChange(newCD);
    return groupData;
  };

  const getAndRemoveGroupData = (groupId: string): CategoryElement[] => {
    const groupData = changedCategoryData.filter(
      (data) => data?.groupId === groupId,
    );
    const newCD = changedCategoryData.filter(
      (data) => data?.groupId !== groupId,
    );
    setChangedCategoryData(newCD);
    checkIfCategoryChange(newCD);

    return groupData;
  };

  const getGroupDataError = (groupId: string | string[]): CategoryElement[] => {
    if (Array.isArray(groupId))
      return changedCategoryData.filter(
        (data) =>
          data !== undefined &&
          data.error !== null &&
          data.error !== '' &&
          groupId.includes(data?.groupId ? data.groupId : ''),
      );
    else
      return changedCategoryData.filter(
        (data) =>
          data !== undefined &&
          data?.error !== null &&
          data?.error !== '' &&
          data?.groupId === groupId,
      );
  };

  const getGroupData = (groupId: string): CategoryElement[] => {
    const groupData = changedCategoryData.filter(
      (data) => data?.groupId === groupId,
    );
    return groupData;
  };

  const checkIfCategoryChange = (newCd?: CategoryElement[]) => {
    const cd = newCd || changedCategoryData;
    const data = cd.filter(
      (data) => data?.dialog === false || data?.dialog === undefined,
    );
    setUnsavedChanges(data.length > 0);
  };

  const removeGroupData = (groupId: string) => {
    setChangedCategoryData(
      changedCategoryData.filter((data) => data?.groupId !== groupId),
    );
    checkIfCategoryChange();
  };

  const discardChanges = () => {
    setUnsavedChanges(false);
    setChangedCategoryData([]);
  };

  const saveChanges = async (forceDelete?: boolean) => {
    try {
      //NOTE: Filter damit man die Form elemente verwenden kann ohne auf die FormAPI zuzugreifen
      const changedCategoryDataFiltered = changedCategoryData.filter(
        (a: any) => {
          return a.customGlobalSave == false || a.customGlobalSave == undefined;
        },
      );
      if (changedCategoryDataFiltered.length > 0) {
        const res = await FormApi.updateFormFields(
          clientId,
          authToken,
          changedCategoryDataFiltered,
        );
        if (res.message) snackbarUtils.success(res.message);
      }
      if (changedCategoryDataFiltered.length > 0 || forceDelete) {
        setChangedCategoryData([]);
        setSaveAll(true);
        setUnsavedChanges(false);
        setTimeout(() => {
          setSaveAll(undefined);
          queryClient.invalidateQueries('formData');
        }, 100);
      }
    } catch (e) {
      snackbarUtils.error(i18n.error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...initialContext,
        isAuthenticated,
        authToken: props.test ? 'test' : authToken,
        authenticate,
        changedCategoryData,
        updateChangedCategoryData,
        unsavedChanges,
        setUnsavedChanges,
        removeChangedCategoryData,
        getAndRemoveGroupData,
        removeGroupData,
        getGroupData,
        getGroupDataError,
        getAndRemoveGroupDataByIdAndGroupId,
        clientId: props.test ? 'test' : clientId,
        setClientId,
        clientList,
        setClientList,
        isAdmin,
        isSuperAdmin,
        userId,
        user,
        logout,
        saveAll,
        saveChanges,
        discardChanges,
        recoveryCode,
        setRecoveryCode,
        recoveryCodeDialogOpen,
        setRecoveryCodeDialogOpen,
        basicActive,
        familyActive,
        riskActive,
        genoGrammActive,
        billingActive,
        avatarActive,
        readOnly,
        setReadOnly,
        drawerOpen,
        setDrawerOpen,
        isMobile,
        isControlling: isControlling,
        sevDeskActive,
        setSevDeskActive,
        isTeamLead,
        newTodos,
        newProtocols,
        newOrgMessages,
        breadCrumbSegments,
        setBreadCrumbSegments,
        backendVersion,
        features,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
export { initialContext as initalContext };
