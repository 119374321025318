import { FrontendFormImportField } from '@casecare/types';
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FC, useContext, useState, useEffect, useCallback } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { MenuWrapper } from '../../core';
import { AppContext } from '../../../hooks/context';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridEventListener,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { loadTitle, setDocumentTitle } from '../../../utils';
import { AdminApi } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faChevronDown,
  faChevronUp,
  faCircleArrowDown,
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import TimeTrackingCategoryDialog from './time-tracking-category-dialog';
import { TimeTrackingCategory } from '../../../types/time-tracking-category';
import DeleteTimeTrackingCategoryDialog from './delete-time-tracking-category-dialog';

interface TimeTrackingCategoryListProps {
  categories?: any[];
  parentId?: string;
  refetch?: () => void;
}

const TimeTrackingCategoryList: FC<TimeTrackingCategoryListProps> = (props) => {
  const context = useContext(AppContext);
  const [rows, setRows] = useState<any>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [selectedTimeTrackingCategory, setSelectedTimeTrackingCategory] =
    useState<TimeTrackingCategory>();
  const apiRef = useGridApiRef();

  useEffect(() => {
    setDocumentTitle(context.i18n.siteTitleAdminTimetrackingcategories);
    return () => loadTitle();
  }, []);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['getTimeTrackingCategories'],
    queryFn: () => AdminApi.getTimeTrackingCategories(context.authToken),
    enabled:
      context.authToken !== undefined &&
      context.isAdmin &&
      props.categories == undefined,
  });

  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef],
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    } else if (data && data.data && data.data) {
      setRows(data.data);
    }
  }, [data, error]);

  useEffect(() => {
    if (props.categories) setRows(props.categories);
  }, [props.categories]);

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: context.i18n.timeTrackingCategoryName,
      flex: 1,
      sortable: false,
    },
    // {
    //   field: 'method',
    // headerName: context.i18n.timeTrackingCategoryMethod,
    //   flex: 1,
    //   sortable: false,
    // },
    {
      field: 'isClientOrFamilyNeeded',
      headerName: context.i18n.timeTrackingCategoryIsClientOrFamilyNeeded,
      flex: 1,
      sortable: false,
      valueFormatter: (value) =>
        context.i18n['timeTrackingCategoryIsClientOrFamilyNeeded' + value] ||
        '',
    },
    {
      field: 'method',
      headerName: context.i18n.timeTrackingCategoryMethod,
      flex: 1,
      sortable: false,
      valueFormatter: (value) =>
        context.i18n['timeTrackingCategoryMethod_' + value] || '',
    },
    {
      field: context.i18n.actions,
      headerName: '',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Tooltip title={context.i18n.timeTrackingCategoryCreate}>
          <IconButton
            disableRipple
            color="primary"
            id="invite-user-button"
            data-id="invite-user-button"
            onClick={() => setCreateDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Box>
          <Tooltip title={context.i18n.timeTrackingCategoryEdit}>
            <IconButton
              disableRipple
              color="primary"
              onClick={() => {
                setEditDialogOpen(true);
                setSelectedTimeTrackingCategory(params.row);
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </IconButton>
          </Tooltip>
          <Tooltip title={context.i18n.timeTrackingCategoryDelete}>
            <IconButton
              disableRipple
              sx={{ height: 39 }}
              color="error"
              id="delete-help-form-button"
              data-id="delete-help-form-button"
              onClick={() => {
                setSelectedTimeTrackingCategory(params.row);
                setDeleteDialogOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
  return (
    <>
      <DataGridPro
        sx={{
          fontSize: 17,
          mx: 1,
        }}
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={isLoading}
        autoHeight
        hideFooter={props.categories != undefined}
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        density="compact"
        disableRowSelectionOnClick
        pagination
        disableColumnResize
        slots={{
          detailPanelExpandIcon: () => <FontAwesomeIcon icon={faChevronDown} />,
          detailPanelCollapseIcon: () => <FontAwesomeIcon icon={faChevronUp} />,
        }}
        onRowClick={onRowClick}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
              // method: props.parentId ? true : false,
              isClientOrFamilyNeeded: props.parentId ? true : false,
              method: props.parentId ? true : false,
            },
          },
        }}
        getDetailPanelContent={
          props.categories == null
            ? ({ row }) => {
                if (row.children)
                  return (
                    <Box sx={{ my: 2, mx: 4 }}>
                      <TimeTrackingCategoryList
                        categories={row.children}
                        parentId={row.id}
                        refetch={refetch}
                      />
                    </Box>
                  );

                return null;
              }
            : undefined
        }
        getDetailPanelHeight={() => 'auto'}
      />

      <TimeTrackingCategoryDialog
        open={createDialogOpen}
        parentId={props.parentId}
        onClose={() => {
          setCreateDialogOpen(false);
          if (props.refetch) props.refetch();
          else refetch();
        }}
      />
      <TimeTrackingCategoryDialog
        open={editDialogOpen}
        parentId={props.parentId}
        timeTrackingCategory={selectedTimeTrackingCategory}
        onClose={() => {
          setEditDialogOpen(false);
          setSelectedTimeTrackingCategory(undefined);
          if (props.refetch) props.refetch();
          else refetch();
        }}
      />
      <DeleteTimeTrackingCategoryDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setSelectedTimeTrackingCategory(undefined);
          if (props.refetch) props.refetch();
          else refetch();
        }}
        timeTrackingCategory={selectedTimeTrackingCategory}
      />
    </>
  );
};

export default TimeTrackingCategoryList;
