import { axiosInstance } from '../../utils';

export default class UserApi {
  static handleUserLink(
    token: string | undefined,
    linkId: string,
    linkType: string,
    password: string,
    recoveryCode?: string,
  ): Promise<any> {
    return axiosInstance
      .post(
        '/user/link',
        Object.assign(
          {
            linkId,
            linkType,
            password,
          },
          recoveryCode && { recoveryCode },
        ),
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .then((res) => {
        return res.data;
      });
  }

  static getUsers(token: string | undefined, body?: any): Promise<any> {
    return axiosInstance
      .post('/user/list', body ? body : {}, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUsersGroups(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/user/groups', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getHelpForms(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/user/help-form', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getUserInfo(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/user/info', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static getOrgMessages(token: string | undefined): Promise<any> {
    return axiosInstance
      .get('/user/organization-message', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }

  static confirmOrgMessageReceipt(
    token: string | undefined,
    messageId: string,
  ): Promise<any> {
    return axiosInstance
      .get('/user/organization-message/' + messageId, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res.data;
      });
  }
}
